@keyframes showBackdrop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showPopup {
  0% {
    opacity: 0;
    transform: translateY(-500px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.4);
  z-index: 1200;

  animation: showBackdrop .3s ease-out
}

.backdrop.loader {
  z-index: 1250;
}

.modal {
  background: #fff;
  width: 320px;
  min-height: 200px;
  padding: 25px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);

  animation: showPopup .3s ease-out
}

.modal-icon svg{
  width: 80px;
  height: 80px;
}
.modal-icon.modal-icon-success svg{
  color: green;
}

.modal-icon.modal-icon-error svg{
  color: red;
}


.modal-header {
  padding: 10px 20px
}

.modal-body {
  padding: 10px 20px
}